import React from 'react'
import Img from 'gatsby-image'
import { WIDTH_LG, WIDTH_MD } from 'src/utils/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { ImgSize, OQueEFinanciamentoImobiliarioSection } from './style'

const OQueEFinanciamentoImobiliario = () => {
  const data = usePageQuery()
  const windowWidth = useWidth(300)

  const titleMD = (windowWidth >= WIDTH_MD) && (windowWidth < WIDTH_LG)

  return (
    <OQueEFinanciamentoImobiliarioSection id='o-que-e-fin-imob' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row'>
          { titleMD && (
            <div className='col-12'>
              <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 d-none d-md-block d-lg-none mb-3'>O que é Financiamento Imobiliário?</h2>
            </div>
          )}
          <div className='col-12 col-md-6 col-lg-5 offset-xl-1 order-md-last mb-4 mb-md-0'>
            <ImgSize>
              <Img fluid={data.herobannerOQueEFinanciamentoImobiliario.fluid} alt='Uma mão prestes a segurar a chave de sua casa própria' />
            </ImgSize>
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6'>
            { !titleMD && (
              <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-4 d-md-none d-lg-block'>O que é Financiamento Imobiliário?</h2>
            )}
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400'>Para comprar seu imóvel, novo ou usado, você pode contar com o crédito imobiliário do Inter! <span className='fw-700'>Financiamos até 80% do valor do imóvel para pagar em 30 anos.</span></p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400'>Você pode fazer seu financiamento pelo SFI ou pelo SFH. O SFH (Sistema Financeiro de Habitação), possui teto máximo de juros de 12% ao ano e você pode usar seu FGTS para amortização ou entrada. Já o SFI (Sistema de Financiamento Imobiliário), permite financiar imóveis de maior valor, porém sem a garantia de um teto de juros.</p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400'>Além disso, você pode usar <span className='fw-700'>até 30% da sua renda mensal familiar bruta no crédito imobiliário.</span></p>
          </div>
        </div>
      </div>
    </OQueEFinanciamentoImobiliarioSection>
  )
}

export default OQueEFinanciamentoImobiliario
