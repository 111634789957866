import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const BlogDoInterSection = styled.section`

  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {

      button {
        background: ${orange.base};
      }
    }
  }
`

export const BlogItem = styled.div`
  img {
    width: 100%;
    border-radius: 1rem;
  }
`
