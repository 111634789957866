import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'

import textEN from '../../assets/data/compreSeuImovelUS-us.json'
import textPT from '../../assets/data/compreSeuImovelUS-pt.json'
import { ContentType } from '../../assets/data/types'

type Props = {
  lang: string;
}

const CompreSeuImovelUS = ({ lang }: Props) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const { title, text, textButton }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <section id='sua-casa-um-clique' className={`${lang === 'pt' ? 'py-5' : 'pb-5' }`}>
      <div className='container'>
        <div className='row'>
          <div className='row justify-content-center justify-content-lg-between align-items-center'>
            <div className='col-11 col-md-6 col-lg-5 mb-4 mb-md-2 mb-lg-0'>
              <Img fluid={data.bannerCompreSeuImovelUs.fluid} alt='Uma casa americana tradicional' />
            </div>
            <div className='col-12 col-md-6 offset-lg-1 pr-xl-0'>
              {lang === 'en' && (
                <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 font-sora text-grayscale--400 mb-3'>Real State</p>
              )}

              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3' dangerouslySetInnerHTML={{ __html: title }} />

              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 mb-md-3' dangerouslySetInnerHTML={{ __html: text }} />

              <a
                href='https://inter.co/pra-voce/financiamento-imobiliario/financiamento-eua/'
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_13',
                      section_name: title,
                      element_action: 'click button',
                      element_name: textButton,
                      redirect_url: 'https://inter.co/pra-voce/financiamento-imobiliario/financiamento-eua/',
                     })
                }}
                dangerouslySetInnerHTML={{ __html: textButton }}
              />

            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default CompreSeuImovelUS
