import React from 'react'
import Img from 'gatsby-image'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { BlogDoInterSection, BlogItem } from './style'
import usePageQuery from '../../pageQuery'

import blogJSON from './assets/data/data.json'

import { BlogType } from './types'

const BlogDoInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const HandClick = (url: string, title: string) => {
    const dataLayerLinkReadMore: IDataLayerParams = {
      section: 'dobra_15',
      element_action: 'click banner',
      element_name: title,
      redirect_url: url,
    }

    sendDatalayerEvent(dataLayerLinkReadMore)
  }

  return (
    <BlogDoInterSection id='quer-ficar-por-dentro' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className=''>
              <h3 className='fs-12 lh-15 fw-700 text-grayscale--500'>BLOG DO INTER</h3>
            </div>
            <div className='ml-3 ml-md-0'>
              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 30 }}
                md={{ items: 2, partialVisibilityGutter: 60 }}
                lg={{ items: 3, partialVisibilityGutter: 30 }}
                xl={{ items: 4 }}
              >
                {
              blogJSON.map((blogData: BlogType) => (
                <div key={blogData.description} className='mr-4'>
                  <BlogItem>
                    <a
                      onClick={() => HandClick(blogData.link, blogData.title)}
                      href={blogData.link}
                      target='_blank' rel='noreferrer'
                    >
                      <Img fluid={data[blogData.image].fluid} alt='Dois profissionais observando a planta de uma casa.' />
                      <h4 className='fs-20 lh-25 fw-600 text-grayscale--500 mt-4 mb-2'>{blogData.title}</h4>
                    </a>
                    <p className='fs-14 lh-17 fw-400 text-grayscale--500 mb-3'>{blogData.description}</p>
                    <span className='fs-12 lh-14 fw-400 text-grayscale--400 mb-0'>{blogData.text}</span>
                  </BlogItem>
                </div>

              ))
              }
              </DefaultCarousel>
            </div>
          </div>
        </div>
      </div>
    </BlogDoInterSection>
  )
}

export default BlogDoInter
