import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const OQueEFinanciamentoImobiliarioSection = styled.section`
  .step-with-border {
    border-bottom: 1px solid #DEDFE4;
  }
`

export const ImgSize = styled.div`
  @media ${device.desktopXL} {
    max-width: 429px;
  }
`
