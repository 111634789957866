import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import QuemEntendeDoAssuntoJSON from '../../assets/data/QuemEntendeDoAssunto.json'

import { Section, Card } from './style'

type QuemEntendeDoAssuntoProps = {
  title: string;
  description: string;
}

const QuemEntendeDoAssunto = () => {
  return (
    <Section className='py-5 bg-orange--base'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-white'>Faça com quem entende do assunto!</h2>
            <p className='fs-16 lh-19 fs-lh-18 lh-lg-22 fw-400 text-white mb-md-0'>
              O Crédito Imobiliário está no nosso DNA e já são milhares de clientes contando com serviços exclusivos: tudo
              online e sem burocracia!
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-7'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 90 }}
              xl={{ items: 2 }}
              itemClass='px-2'
              customColor='white'
            >
              {
                QuemEntendeDoAssuntoJSON.map((item: QuemEntendeDoAssuntoProps) => (
                  <Card key={item.title}>
                    <span className='fs-18 lh-22 fw-700 text-orange--extra mb-2 d-block'>{item.title}</span>
                    <p
                      className='fs-14 lh-17 fw-400 text-grayscale--500 mb-0'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default QuemEntendeDoAssunto
