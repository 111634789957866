import React, { Dispatch, SetStateAction } from 'react'
import Img from 'gatsby-image'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import { green } from 'src/styles/colors'

import usePageQuery from '../../pageQuery'

import { SuaCasaEstaUmCliqueSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface ISuaCasaEstaUmClique{
  setSimulator: Dispatch<SetStateAction<string>>;
}

const SuaCasaEstaUmClique = ({ setSimulator }: ISuaCasaEstaUmClique) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const url = 'https://imobiliario.inter.co/financiamento-imobiliario/'

  return (
    <SuaCasaEstaUmCliqueSection id='sua-casa-um-clique' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='row justify-content-center justify-content-lg-between align-items-center'>
            <div className='col-11 col-md-6 col-lg-5 mb-4 mb-md-2 mb-lg-0'>
              <Img
                fluid={data.bannerSuaCasaUmClique.fluid}
                alt='Dois profissionais observando a planta de uma casa.'
              />
            </div>
            <div className='col-12 col-md-6 offset-lg-1 pr-xl-0'>
              <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'>
                Sua casa está a um <span className='d-block d-md-inline'>clique: nós fazemos tudo</span> por você!
              </h2>
              <div className='d-flex align-items-center mb-3'>
                <div className='mr-1'>
                  <OrangeIcon size='MD' color={green[500]} icon='smartphones' />
                </div>
                <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-700 sucess-text mb-0'>Processos rápidos e atendimento via WhatsApp</p>
              </div>
              <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4 mb-md-3'>
                Financiar seu imóvel não precisa ser complicado. No Inter o processo é digital, simples, rápido e sem sair de casa.
                Temos um time de especialistas preparados para auxiliar com todas as etapas do seu financiamento.
              </p>
              <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-700 text-grayscale--400 mb-4 mb-md-3 mb-lg-5'>
                Assim, sobra tempo para você planejar a nova casa do jeito que sempre sonhou!
              </p>
              <div
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Quero simular!',
                    redirect_url: url,
                   })
                }}
              >
                <UTMLink
                  text='Quero simular!'
                  link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
                  campanha='site-pf-financiamento'
                  btncolor='btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                  setSimulator={setSimulator}
                  sectionName='Sua casa está a um clique: nós fazemos tudo por você!'
                  dobra='dobra_03'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SuaCasaEstaUmCliqueSection>
    )
}

export default SuaCasaEstaUmClique
