import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bgFinanciamentoImobiliarioOrangeFriday: imageSharp(fluid: { originalName: { regex: "/bg-financiamento-imobiliario-orange-friday/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      melhorTaxaFinanciamentoImobiliario: imageSharp(fluid: {originalName: { regex: "/melhor-taxa-financiamento-imobiliario/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgFinanciamentoResidensial: imageSharp(fluid: {originalName: { regex: "/img-financiamento-residencial/" }}) {
        fluid(maxWidth: 373, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      novaTaxaJurosPoupanca: imageSharp(fluid: {originalName: { regex: "/nova-taxa-juros-poupanca/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      primeiroPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-primeiro-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      segundoPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-segundo-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      terceiroPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-terceiro-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      quartoPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-quarto-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      imobiliarioComoFinanciar: imageSharp(fluid: {originalName: { regex: "/imobiliario-como-financiar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      construirSuaCasa: imageSharp(fluid: {originalName: { regex: "/construir-sua-casa/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      herobannerOQueEFinanciamentoImobiliario: imageSharp(fluid: { originalName: { regex: "/hero-banner-o-que-e-financiamento-imobiliario/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      financiarImovelNoInter: imageSharp(fluid: { originalName: { regex: "/como-financiar-imovel-no-inter/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      herobannerTRPoupancaOuIPCA: imageSharp(fluid: { originalName: { regex: "/hero-banner-tr-poupanca-ou-ipca/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerBlogComoFuncionaOFinImobInter: imageSharp(fluid: { originalName: { regex: "/blog-banner-como-funciona-o-fin-imob-no-inter/" } }) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerBlogGuiaCompletoDoFinImob: imageSharp(fluid: { originalName: { regex: "/blog-banner-guia-completo-do-fin-imob/" } }) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerBlogComoUsarOFgtsParaAmortizarOFin: imageSharp(fluid: { originalName: { regex: "/blog-banner-como-utilizar-o-fgts-para-amortizar/" } }) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerBlogDezoitoDuvidasComunsSobreOFinImob: imageSharp(fluid: { originalName: { regex: "/blog-banner-18-duvidas-comuns-sobre-financiamento-imobiliario/" } }) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerSuaCasaUmClique: imageSharp(fluid: { originalName: { regex: "/banner-sua-casa-esta-a-um-clique/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerFinancieNoInterEAproveite: imageSharp(fluid: { originalName: { regex: "/banner-financie-no-inter-e-aproveite-mobile/" } }) {
        fluid(maxWidth: 332, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      financiamentoConstrucasaTabSection: imageSharp(fluid: { originalName: { regex: "/financiamento-construcasa-tab-section/" } }) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      financiamentoPortabilidadeTabSection: imageSharp(fluid: { originalName: { regex: "/financiamento-portabilidade-tab-section/" } }) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerCompreSeuImovelUs: imageSharp(fluid: { originalName: { regex: "/banner-compre-seu-imovel-us/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
