import React, { Dispatch, SetStateAction } from 'react'
import Img from 'gatsby-image'

import Tab from 'src/components/Tab'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import Link from 'src/components/GatsbyLinkWrapper/index'

import tabJSON from '../../assets/data/tab-selection.json'
import pageQuery from '../../pageQuery'

import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const tabTitles = [ 'Portabilidade', 'Construcasa' ]

type tabJSONProps = {
  image: string;
  title: string;
  description: string;
  utm: boolean;
}

interface IQuerEconomizarNasParcelas {
  setSimulatorPortability: Dispatch<SetStateAction<string>>;
}

const TabSelection = ({ setSimulatorPortability }: IQuerEconomizarNasParcelas) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = pageQuery()
  const url = 'https://inter.co/pra-voce/financiamento-imobiliario/construcasa/'

  return (
    <Section className='py-5' id='reducao-do-valor-das-parcelas'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Tab
              items={tabTitles}
              border={true}
              activeColor='#FF7A00'
              styles='fs-14 lh-17'
            >
              {
                tabJSON.map((item: tabJSONProps) => (
                  <div className='row d-flex align-items-center justify-content-between mt-5' key={item.title}>
                    <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
                      <Img fluid={data[item.image].fluid} />
                    </div>
                    <div className='col-12 col-md-6'>
                      <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 fw-600'>{item.title}</h2>
                      <p className='fs-16 lh-19 fs-fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-0'>{item.description}</p>
                      {
                        item.utm ? (
                          <div>
                            <UTMLink
                              text='Simular minha portabilidade'
                              link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
                              setSimulatorPortability={setSimulatorPortability}
                              campanha='site-pf-financiamento'
                              btncolor='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none text-center mt-2 mt-md-3'
                              sectionName={item.title}
                              dobra='dobra_14'
                            />
                          </div>
                        ) : (
                          <div onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_14',
                              element_action: 'click button',
                              element_name: 'Conhecer o Construcasa',
                              redirect_url: url,
                             })
                          }}
                          >
                            <Link
                              to='/pra-voce/financiamento-imobiliario/construcasa/'
                              title='Conhecer o Construcasa'
                              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none text-center mt-2 mt-md-4'
                            >
                              Conhecer o Construcasa
                            </Link>
                          </div>
                        )
                      }
                    </div>
                  </div>
                ))
              }
            </Tab>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TabSelection
