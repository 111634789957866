import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import dataJson from './assets/data/data.json'

import { QuaisOsCustosDoCreditoImobiliarioSection, Card } from './style'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const QuaisOsCustosDoCreditoImobiliario = () => {
  const data: CardType[] = dataJson

  return (
    <QuaisOsCustosDoCreditoImobiliarioSection id='quais-os-custos-do-cred-imob' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-lg-6 col-xl-5 pr-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3'>
              Quais os custos do <span className='d-md-block'>crédito imobiliário?</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-0'>
              Alguns custos extras devem ser considerados no planejamento financeiro. <span className='fw-700'>Aqui no Inter você pode incluir as despesas do financiamento,</span> ITBI e Registro de Imóvel <span className='fw-700'>nas parcelas para ter ainda mais praticidade e tranquilidade!</span>
            </p>
          </div>
          <div className='col-12 col-md-6 offset-md-1 offset-lg-0 offset-xl-1'>
            <DefaultCarousel
              xl={{ items: 1, partialVisibilityGutter: 169 }}
              lg={{ items: 1, partialVisibilityGutter: 70 }}
              md={{ items: 1 }}
            >
              {data.map((CardData: CardType) => (
                <Card key={CardData.icon} className='mr-lg-4'>
                  <OrangeIcon size='LG' color='#ff7a00' icon={CardData.icon} />
                  <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2 mt-4'>{CardData.title}</h3>
                  <p className='fs-16 lh-20 text-grayscale--400 mb-0'>{CardData.description}</p>
                </Card>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </QuaisOsCustosDoCreditoImobiliarioSection>
  )
}

export default QuaisOsCustosDoCreditoImobiliario
