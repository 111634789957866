import React from 'react'
import FAQ from 'src/components/Faq'

interface IFaqSection {
  faqData: {
    question: string;
    answer: string;
  }[];
}

const FaqSection = ({ faqData }: IFaqSection) => {
  return (
    <section id='faq' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='mb-4'>
            <h3 className='fs-24 lh-30 fs-md-40 lh-md-50 fw-600 text-grayscale--500'>
              Perguntas frequentes sobre Financiamento Imobiliário
            </h3>
          </div>
          <FAQ
            answerData={faqData} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
          />
        </div>
      </div>
    </section>
    )
}

export default FaqSection
