import React, { useEffect, useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'

import pageContext from './pageContext.json'
import { WIDTH_XL } from 'src/utils/breakpoints'
import useDomReady from 'src/hooks/window/useDomReady'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidthResize from 'src/hooks/window/useWidth'
import useSessionStorage from 'src/hooks/window/useSessionStorage'

import {
  Hero,
  SuaCasaEstaUmClique,
  SuaCasaNova,
  SumarySection,
  OQueEFinanciamentoImobiliario,
  QuaisAsVantagensDoFI,
  TRPoupancaOuIPCA,
  CompareAsTaxas,
  ComoPossoUsarMeuFGTS,
  ComoFinanciarOImovelNoInter,
  QuaisOsCustosDoCreditoImobiliario,
  BlogDoInter,
  FaqSection,
  TabSection,
  QuemEntendeDoAssunto,
  CompreSeuImovelUS,
} from './sections/_index'

import {
  Wrapper,
} from './style'

const FinanciamentoImobiliarioResidencial = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)
  const isDesktop = windowWidth > WIDTH_XL
  const [ , setSimulator ] = useState('')
  const [ , setSimulatorPortability ] = useState('')

  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-financiamento-imobiliario', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  const lang = 'pt'

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setPopup} pageName='financiamento-imobiliario-residencial' />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {PopupExitPage}
        <Hero setSimulator={setSimulator} />
        <SumarySection sendDatalayerEvent={sendDatalayerEvent} />
        <SuaCasaEstaUmClique setSimulator={setSimulator} />
        <OQueEFinanciamentoImobiliario />
        <ComoFinanciarOImovelNoInter setSimulator={setSimulator} />
        <QuaisAsVantagensDoFI />
        <SuaCasaNova setSimulator={setSimulator} />
        <TRPoupancaOuIPCA />
        <CompareAsTaxas setSimulator={setSimulator} />
        <ComoPossoUsarMeuFGTS />
        <QuaisOsCustosDoCreditoImobiliario />
        <QuemEntendeDoAssunto />
        <CompreSeuImovelUS lang={lang} />
        <TabSection setSimulatorPortability={setSimulatorPortability} />
        <BlogDoInter />
        <FaqSection faqData={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default FinanciamentoImobiliarioResidencial
