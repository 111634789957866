export type DataProps = {
  question?: string;
  answer?: string;
  name?: string;
  description?: string;
  content?: ContentProps[];
};

type ContentProps = {
  position?: number;
  name?: string;
  image?: string;
  text?: string;
};

export const structuredDataGenerator = (
  data?: DataProps[] | DataProps,
  type?: string,
) => {
  let parsedData
  const howToData = data as DataProps
  const faqData = data as DataProps[]

  switch (type) {
    case 'faq':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          faqData.map((item: DataProps) => {
            return {
              '@type': 'Question',
              name: item.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer,
              },
            }
          }),
        ],
      }
      break

    case 'howTo':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: howToData.name,
        description: howToData.description,
        step: [
          howToData?.content?.map((item: ContentProps) => {
            return {
              '@type': 'HowToStep',
              position: item.position,
              image: item.image,
              name: item.name,
              text: item.text,
            }
          }),
        ],
      }
  }

  return parsedData
}
