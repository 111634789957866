import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const QuaisOsCustosDoCreditoImobiliarioSection = styled.section`

  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {

      button {
        background: ${orange.base};
      }
    }
  }
`

export const Card = styled.div`
  background-color: ${white};
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    min-height: 216px;
  }
`
