import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const SuaCasaEstaUmCliqueSection = styled.section`


  .sucess-text {
    color: ${green[400]};
  }

  .btn-orange--extra {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    @media ${device.tablet} {
      height: 48px;
    }
    @media ${device.desktopLG} {
      min-width: 100% !important;
    }
  }
`
