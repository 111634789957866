import styled from 'styled-components'

export const Section = styled.section`
  .react-multi-carousel-dot {

    button {
      background-color: #DEDFE4 !important;
    }

    &-active {
      button {
        background-color: #fff !important;
      }
    }
  }
`

export const Card = styled.div`
  width: 100%;
  height: 156px;
  padding: 24px 24px 12px;
  background: #fff;
  border-radius: 12px;
`
