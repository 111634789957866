import React from 'react'
import Img from 'gatsby-image'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Icon from 'src/components/UI/MarkdownIcon'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import trPoupancaOuIPCA from './assets/data/data.json'

import usePageQuery from '../../pageQuery'

import { PerfectForYouSection, Card, CardMobileDiv } from './style'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const TRPoupancaOuIPCA = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const windowWidth = useWidthResize()
  const isMobile = windowWidth < WIDTH_MD
  const url = 'https://inter.co/pra-voce/financiamento-imobiliario/residencial/taxa-bonificada/'

  return (
    <PerfectForYouSection id='qual-a-taxa-ideal' className='py-5'>
      <div className='container'>
        <div className='row align-items-lg-center align-items-xl-start'>
          <div className='col-12 col-md-6 col-lg-5 mt-xl-4 mb-4 mb-md-0 mt-md-5 mt-lg-0'>
            <Img fluid={data.herobannerTRPoupancaOuIPCA.fluid} alt='Casal feliz com a aquisição de um imóvel.' />
          </div>
          <div className='col-12 col-md-6 offset-lg-1 pr-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3'>
              TR ou IPCA: <span className='d-md-block'>qual a taxa ideal?</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              No Inter seu financiamento imobiliário pode ser atrelado aos seguintes fatores de correção: Taxa Referencial (TR) ou IPCA em caso de imóveis comerciais.
            </p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-5 mb-md-0'>
              <span className='fw-700'>Para contratos atrelados à TR:</span> os clientes elegíveis podem usufruir da taxa bonificada, uma taxa de juros com desconto e condições especiais.
              <div onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Saiba Mais!',
                  redirect_url: url,
                 })
              }}
              >
                <Link
                  to='/pra-voce/financiamento-imobiliario/residencial/taxa-bonificada/'
                  className='text-orange--extra' title='Saiba Mais!'
                > Saiba Mais!
                </Link>
              </div>
            </p>
            {isMobile
              ? (
                <CardMobileDiv className='row justify-content-center'>
                  {trPoupancaOuIPCA.map((CardData: CardType) => (
                    <Card className='bg-grayscale--100' key={CardData.icon}>
                      <Icon width='24' height='24' icon={CardData.icon} directory='v2' color='orange--extra' />
                      <p className='fs-18 lh-22 text-grayscale--500 fw-700 mb-3 mt-4'>{CardData.title}</p>
                      <p className='fs-14 lh-17 text-gray--700'>{CardData.description}</p>
                    </Card>
                  ))}
                </CardMobileDiv>
              )
              : (
                <DefaultCarousel
                  xl={{ items: 2 }}
                  lg={{ items: 1, partialVisibilityGutter: 170 }}
                  md={{ items: 1, partialVisibilityGutter: 51 }}
                >
                  {trPoupancaOuIPCA.map((CardData: CardType) => (
                    <Card className='bg-grayscale--100 mr-4' key={CardData.icon}>
                      <Icon width='24' height='24' icon={CardData.icon} directory='v2' color='orange--extra' />
                      <p className='fs-18 lh-22 text-grayscale--500 fw-700 mb-3 mt-3'>{CardData.title}</p>
                      <p className='fs-14 lh-17 text-gray--700 mb-0'>{CardData.description}</p>
                    </Card>
                    ))}
                </DefaultCarousel>
            )}
          </div>
        </div>
      </div>
    </PerfectForYouSection>
  )
}

export default TRPoupancaOuIPCA
