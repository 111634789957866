import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background-color: ${white};

  .form--default {   
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    .form-label-check {    
      &::before {
        top: 34px;

        @media (min-width: ${breakpoints.md}) {
          top: 26px;
        }
      }   
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 37px; 
          @media (min-width: ${breakpoints.md}) {
            top: 29px;
          }    
        }
      }
    }
  }

  button[type="submit"] {
    height: 44px
  }
`
