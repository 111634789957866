import React from 'react'
import Helmet from 'react-helmet'

import {
  DataProps,
  structuredDataGenerator,
} from './../structuredDataGenerator'

type howToProps = {
  children: React.ReactNode;
  data?: DataProps;
};

const HowTo = ({ children, data }: howToProps) => {
  const parsedData = structuredDataGenerator(data, 'howTo')

  return (
    <>
      <Helmet>
        <script
          id='structured-data-how-to'
          type='application/ld+json'
        >{`${JSON.stringify(parsedData)}`}
        </script>
      </Helmet>
      {children && children}
    </>
  )
}

export default HowTo
