import React, { Dispatch, SetStateAction } from 'react'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Icon from 'src/components/UI/MarkdownIcon'

import taxesSectionContent from './assets/data/data.json'

import { CompareAsTaxasSection, FeesCard, CardDiv } from './style'

interface ICompareAsTaxas {
  setSimulator: Dispatch<SetStateAction<string>>;
}

type CardType = {
  title: string;
  list: {
      icon: string;
      title: string;
      description: string;
  }[];
  taxaBonificada?: boolean;
}

type ItemsListType = {
  icon: string;
  title: string;
  description: string;
}

const CompareAsTaxas = ({ setSimulator }: ICompareAsTaxas) => {
  return (
    <CompareAsTaxasSection id='compare-as-taxas' className='bg-gray py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 text-md-center mb-0 mb-md-4'>
              Compare as taxas de financiamento:
            </h2>
          </div>
        </div>
        <div className='row justify-content-center'>
          <CardDiv className='col-12'>
            <DefaultCarousel
              containerClass='mx-n2 mx-md-0'
              md={{ items: 2 }}
              lg={{ items: 2 }}
              xl={{ items: 2 }}
            >
              {taxesSectionContent.map((instruction: CardType) => (
                <FeesCard key={instruction.title} className='bg-white border rounded-3'>
                  <div className='mt-2 mb-4 mb-md-0 px-2 px-md-0 border-bottom'>
                    <h3 className='fs-20 lh-24 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 fs-xl-24 lh-xl-30 text-grayscale--500 fw-600 sora mb-md-4'>{instruction.title}</h3>
                  </div>
                  <ul className='list-unstyled mb-4'>
                    {
                      instruction.list.map((listItem: ItemsListType) => (
                        <li key={listItem.title} className='d-flex mb-md-4 mb-lg-0'>
                          <div className='mr-3'>
                            <span><Icon color='orange--base' width='24' height='24' icon={listItem.icon} directory='v2' /></span>
                          </div>
                          <div>
                            <p className='fs-16 text-grayscale--500 fw-700 mb-2'>{listItem.title}</p>
                            <p className='fs-16 lh-18 text-gray--700 mb-0'>{listItem.description}</p>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  {instruction.taxaBonificada && (
                    <div className='d-flex align-items-center'>
                      <div className='mr-2'><Icon color='orange--base' width='24' height='24' icon='products/seguros/taxa-menores' directory='v2' /></div>
                      <div>
                        <p className='fs-16 lh-20 fw-700 text-grayscale--500 mb-0'>Taxa bonificada</p>
                      </div>
                    </div>
                  )}
                </FeesCard>
              ))
              }
            </DefaultCarousel>
          </CardDiv>
        </div>
        <div className='col-12 text-center d-none d-md-block'>
          <UTMLink
            text='Simular financiamento imobiliário'
            link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
            setSimulator={setSimulator}
            campanha='site-pf-financiamento'
            btncolor='btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none'
            sectionName='Compare as taxas de financiamento:'
            dobra='dobra_09'
          />
        </div>
      </div>
    </CompareAsTaxasSection>
  )
}

export default CompareAsTaxas
