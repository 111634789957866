import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const QuerFicarPorDentroSection = styled.section`
  padding: 40px 0;
  @media ${device.desktopLG} {
    padding: 80px 0;
  }
  @media ${device.desktopXL} {
    padding: 97px 0;
  }
  .form-label-check {
    padding-left: 40px;
  }

  .form--default {
    .form-label-check {
      font-size: 12px;
      &::before {
        border: 2px solid ${grayscale[400]};
        top: 4px;
      }
    }
   .form-input-check:checked ~ label  {
      &::after {
        top: 7px;
      }
    }
  }
`
