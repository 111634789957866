import React, { Dispatch, SetStateAction } from 'react'
import Img from 'gatsby-image'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import HowTo from 'src/components/StructuredData/HowTo'

import usePageQuery from '../../pageQuery'
import pageContext from '../../pageContext.json'

import { PortabilityStepSection, PortabilityStepNumber } from './style'

interface ICompareAsTaxas {
  setSimulator: Dispatch<SetStateAction<string>>;
}

type EtapaType = {
  position: number;
  name: string;
  text: string;
}

const ComoFinanciarOImovelNoInter = ({ setSimulator }: ICompareAsTaxas) => {
  const data = usePageQuery()
  const howToData = pageContext.structuredData.howTo

  return (
    <PortabilityStepSection id='como-financiar-imovel-no-inter' className='py-5'>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-5 mb-md-0'>
            <Img
              fluid={data.financiarImovelNoInter.fluid}
              alt='Ivete apontando para a direita, onde tem as informações de como financiar imóvel no Inter'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3'>Como financiar <span className='d-lg-block'>imóvel no Inter?</span></h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-gray--700 mb-4'>
              Financiar seu imóvel com o Inter é mais rápido e prático! São apenas 5 etapas! Veja:
            </p>
            <HowTo data={howToData}>
              {howToData.content.map((EtapaData: EtapaType, index: number) => (
                <div key={EtapaData.position} className={`d-flex align-items-start ${index < (howToData.content.length - 1) ? 'step-with-border' : ''} pb-3 mb-3`}>
                  <PortabilityStepNumber className='fs-14 lh-14 text-white'>{EtapaData.position}</PortabilityStepNumber>
                  <div>
                    <h3 className='text-grayscale--400 fs-14 fw-700 lh-17 mb-2'>{EtapaData.name}</h3>
                    <p className='text-grayscale--400 fs-14 fw-400 lh-17 mb-0'>{EtapaData.text}</p>
                  </div>
                </div>
              ))}
            </HowTo>
            <UTMLink
              text='Simular financiamento imobiliário'
              link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
              campanha='site-pf-financiamento'
              btncolor='btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              setSimulator={setSimulator}
              sectionName='Como financiar imóvel no Inter?'
              dobra='dobra_05'
            />
          </div>
        </div>
      </div>
    </PortabilityStepSection>
  )
}

export default ComoFinanciarOImovelNoInter
