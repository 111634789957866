import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundMobile from '../../assets/images/background-como-usar-meu-fgts-fin-imob-mobile.png'
import BackgroundTablet from '../../assets/images/background-como-usar-meu-fgts-fin-imob-tablet.png'
import BackgroundLG from '../../assets/images/background-como-usar-meu-fgts-fin-imob-lg.png'
import BackgroundXL from '../../assets/images/background-como-usar-meu-fgts-fin-imob-xl.png'

export const ComoPossoUsarMeuFGTSSection = styled.section`
  height: 976px;
  background: url(${BackgroundMobile}) no-repeat top right;
  background-size: contain;

  @media (min-width: 555px) {
    background-size: 555px;
  }

  @media ${device.tablet} {
    height: auto;
    background: url(${BackgroundTablet}) no-repeat top right;
    background-size: auto;
  }
  @media ${device.desktopLG} {
    background: url(${BackgroundLG}) no-repeat top right;
  }
  @media ${device.desktopXL} {
    height: 690px;
    background: url(${BackgroundXL}) no-repeat top right;
  }

  h2 {
    @media ${device.desktopXL} {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
`
export const Card = styled.div`
  svg {
    @media ${device.tablet} {
      width: 32px;
      height: 32px;
    }
  }

  h3 {
    @media ${device.desktopXL} {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  p {
    @media ${device.desktopXL} {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
`
