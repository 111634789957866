import React, { Dispatch, SetStateAction } from 'react'
import Img from 'gatsby-image'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import CardData from './assets/data/data.json'

import usePageQuery from '../../pageQuery'
import { orange } from 'src/styles/colors'

import { SuaCasaNovaSection } from './style'
interface ISuaCasaNova {
  setSimulator: Dispatch<SetStateAction<string>>;
}

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const SuaCasaNova = ({ setSimulator }: ISuaCasaNova) => {
  const data = usePageQuery()

  return (
    <SuaCasaNovaSection id='financie-no-inter-aproveite-cartao-inter-black' className='d-md-flex align-items-md-center justify-content-md-center py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 d-block d-md-none'>
            <Img fluid={data.bannerFinancieNoInterEAproveite.fluid} alt='Ilustração Cartão Black' />
          </div>
          <div className='col-12 col-md-9 col-lg-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3'>
              Financie no Inter e aproveite seu cartão Inter Black recheado de benefícios!
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-5'>
              Ao contratar o seu financiamento, você aproveita as vantagens de se tornar um cliente
              <span className='text-orange--extra'> Inter One </span>!
              Aproveite o auxílio de um advisor e cartão Inter Black exclusivo!
            </p>
            <div className='d-none d-lg-block'>
              <UTMLink
                text='Solicite seu cartão Black Inter'
                link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
                campanha='site-pf-financiamento'
                btncolor='btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                setSimulator={setSimulator}
                sectionName='Financie no Inter e aproveite seu cartão Inter Black recheado de benefícios!'
                dobra='dobra_07'
              />
            </div>
          </div>
          <div className='col-12 col-md-6 offset-xl-1 mt-md-5 mt-lg-0'>
            <div className='mb-5'>
              {CardData.map((CardData: CardType) => (
                <div className='d-flex align-items-center mb-4' key={CardData.icon}>
                  <div className='mr-3'>
                    <OrangeDSIcon icon={CardData.icon} color={orange.extra} size='MD' />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 text-grayscale--500 fw-700 mb-0'>{CardData.title}</h3>
                  </div>
                </div>
              ))}
            </div>
            <div className='d-lg-none'>
              <UTMLink
                text='Solicite seu cartão Black Inter'
                link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
                campanha='site-pf-financiamento'
                btncolor='btn-orange--extra btn--lg rounded-2 fs-14 text-none'
                setSimulator={setSimulator}
                sectionName='Financie no Inter e aproveite seu cartão Inter Black recheado de benefícios!'
                dobra='dobra_07'
              />
            </div>
          </div>
        </div>
      </div>
    </SuaCasaNovaSection>
  )
}

export default SuaCasaNova
