import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[100]};
  min-height: 730px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    min-height: 573px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 534px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 677px;
  }

  ul {
    border-bottom: 4px solid ${white};

    li {
      margin: 0;

      & + li {
        margin-left: 32px;
      }

      button {
        margin-bottom: 20px;

        &:after {
          margin-top: 20px;
          position: absolute;
        }
      }
    }
  }
`
