import React from 'react'
import useWidthResize from 'src/hooks/window/useWidth'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Icon from 'src/components/UI/MarkdownIcon'
import Hide from '@interco/icons/build-v4/orangeds/MD/hide'

import moreFlexibilitySectionContent from './assets/data/data.json'

import { WIDTH_LG } from 'src/utils/breakpoints'

import { PerfectForYouSection, Card, CardDesktopDiv } from './style'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const QuaisAsVantagensDoFI = () => {
  const windowWidth = useWidthResize()
  const isMobile = windowWidth < WIDTH_LG

  return (
    <PerfectForYouSection id='quais-as-vantagens-do-fi-imob' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 text-md-center mb-3'>
              <span className='d-md-block d-lg-inline'>Quais são as vantagens</span> do <span className='d-lg-block'>Financiamento Imobiliário Inter?</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 text-md-center mb-0 mb-md-4'>
              Mais flexibilidade para ter seu imóvel. Pesquise com a gente a melhor oportunidade para seu financiamento.
              No Inter, o processo de contratação é digital, rápido e descomplicado.
            </p>
          </div>
          <div className='col-12'>
            {isMobile
              ? (
                <DefaultCarousel
                  xl={{ items: 8 }}
                  lg={{ items: 8 }}
                  md={{ items: 2, partialVisibilityGutter: 51 }}
                  sm={{ items: 1, partialVisibilityGutter: 30 }}
                >
                  {moreFlexibilitySectionContent.map((CardData: CardType) => (
                    <Card className='bg-white mr-4' key={CardData.icon}>
                      {CardData.icon === 'hide'
                      ? <Hide height={24} width={24} color='#FF7A00' />
                      : <Icon width='24' height='24' icon={CardData.icon} directory='v2' color='orange--extra' />}
                      <h3 className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1 mt-4'>{CardData.title}</h3>
                      <p className='fs-14 lh-17 text-gray--700'>{CardData.description}</p>
                    </Card>
                    ))}
                </DefaultCarousel>
              )
              : (
                <CardDesktopDiv className='row justify-content-center'>
                  {moreFlexibilitySectionContent.map((CardData: CardType) => (
                    <Card className='bg-white' key={CardData.icon}>
                      {CardData.icon === 'hide'
                      ? <Hide height={24} width={24} color='#FF7A00' />
                      : <Icon width='24' height='24' icon={CardData.icon} directory='v2' color='orange--extra' />}
                      <h3 className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1 mt-4'>{CardData.title}</h3>
                      <p className='fs-14 lh-17 text-gray--700'>{CardData.description}</p>
                    </Card>
                  ))}
                </CardDesktopDiv>
            )}
          </div>
        </div>
      </div>
    </PerfectForYouSection>
  )
}

export default QuaisAsVantagensDoFI
