import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CompareAsTaxasSection = styled.section`
  .btn {
    @media ${device.tablet} {
      height: 40px;
    }
  }
`

export const FeesCard = styled.section`
  padding: 24px;
  min-height: 266px;

  @media ${device.tablet} {
    min-height: 220px;
    padding: 12px;
    
    .border-bottom {
      border: none !important;
    }
  }
  @media ${device.desktopLG} {
    padding: 24px;
    min-height: 256px;
  }
  @media ${device.desktopXL} {
    min-height: 250px;
  }
`
export const CardDiv = styled.div`
  @media ${device.tablet} {
    div .react-multi-carousel-track li {
      section {
        margin-right: 15px;
      }
      &:last-child {
        section {
          margin-right: 0;
        }
      }
    }
  }
`
