import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const PerfectForYouSection = styled.section`

  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {

      button {
        background: ${orange.base};
      }
    }
  }
`

export const Card = styled.div`
  border-radius: 12px;
  width: 269px;
  min-height: 208px;
  padding: 24px;

  h3 {
    font-family: 'Inter' !important;
  }

  @media ${device.tablet} {
    width: auto;
    min-height: 249px;
  }
  @media ${device.desktopLG} {
    max-width: 264px;
  }
`

export const CardMobileDiv = styled.div`
  gap: 24px;
`
