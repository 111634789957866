import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import dataJson from './assets/data/data.json'

import { ComoPossoUsarMeuFGTSSection, Card } from './style'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const ComoPossoUsarMeuFGTS = () => {
  const data: CardType[] = dataJson
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://blog.inter.co/como-usar-o-fgts-para-a-compra-de-imovel'

  return (
    <ComoPossoUsarMeuFGTSSection id='como-posso-usar-meu-fgts' className='d-flex align-items-end align-items-xl-center bg-white py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-xl-6'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 mb-3'>
              Como posso usar meu FGTS
              <span className='d-md-block d-lg-inline d-xl-block'> no financiamento  do imóvel?</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              Você pode{' '}
              <a
                href={url}
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_10',
                    element_action: 'click button',
                    element_name: 'usar seu FGTS no financiamento imobiliário',
                    redirect_url: url,
                   })
                }}
              >
                usar seu FGTS no financiamento imobiliário{' '}
              </a>
              <span className='d-lg-block'>
                para compor o valor da entrada ou na amortização de parcelas.
              </span>
            </p>
            <div>
              {data.map((CardData: CardType) => (
                <Card key={CardData.icon} className='d-flex mb-4 pr-xl-4'>
                  <div className='mr-3'>
                    <OrangeIcon size='MD' color='#ff7a00' icon={CardData.icon} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 fs-xl-16 lh-xl-20 fw-600 text-grayscale--500 mb-2'>{CardData.title}</h3>
                    <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 fs-xl-14 lh-xl-17 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: CardData.description }} />
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ComoPossoUsarMeuFGTSSection>
  )
}

export default ComoPossoUsarMeuFGTS
