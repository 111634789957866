import React from 'react'

import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ResidencialNewsletterForm from 'src/components/UI/Forms/ResidencialNewsletterForm'

import { QuerFicarPorDentroSection } from './style'

const QuerFicarPorDentro = () => {
  return (
    <QuerFicarPorDentroSection id='quer-ficar-por-dentro'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-6 mb-4'>
            <div className='d-md-flex d-lg-block'>
              <div className='mb-4 mr-md-5'>
                <OrangeDSIcon
                  size='LG'
                  color='#161616'
                  icon='email'
                />
              </div>
              <div>
                <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Quer ficar por dentro de todas as novidades sobre Crédito Imobiliário?</h2>
                <p className='fs-18 lh-22 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--400 mb-0'>Assine a newsletter e receba conteúdos informativos em seu e-mail.</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-5 offset-xl-1'>
            <ResidencialNewsletterForm />
          </div>
        </div>
      </div>
    </QuerFicarPorDentroSection>
  )
}

export default QuerFicarPorDentro
